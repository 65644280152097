import cryptoJs from 'crypto-js'

export function encryptByDES(value) {
  if(value == '') return ''
  let key = '318@Sany'
  let keyHex = cryptoJs.enc.Utf8.parse(key)
  let encrypted = cryptoJs.DES.encrypt(value, keyHex, {
      mode: cryptoJs.mode.ECB,
      padding: cryptoJs.pad.Pkcs7
  })
  return encrypted.toString()
}
