<template>
  <div class="login-page">
    <div class="logo">
      <img src="../../assets/images/logo.png" />
    </div>
    <div class="sys-name">
      <p class="name-zh">车载视频监控管理后台</p>
      <p class="name-en">Vehicle video surveillance management system</p>
    </div>
    <div class="login-form-box">
      <div style="text-align:center">
        <h1>登陆</h1>
        <div class="line"></div>
      </div>
      <el-form class="form" ref="form" :model="form"  :rules="rules" label-width="0px"  @keyup.enter.native="login">
        <el-form-item prop="userAccount">
          <el-input v-model="form.userAccount" placeholder="账号" size="large"  ref="userAccount">
            <template slot="prefix">
              <img src="../../assets/images/user.png" class="icon_left">
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model="form.password" placeholder="密码" show-password>
            <template slot="prefix">
              <img src="../../assets/images/password.png" class="icon_left">
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input v-model="form.code" placeholder="验证码">
            <template slot="prefix">
              <img src="../../assets/images/code.png" class="icon_left">
            </template>
             <template slot="append">
               <img :src="codeImg" @click="getLoginCode"/>
             </template>
          </el-input>
        </el-form-item>
      </el-form>
      <div class="logo-submit" @click.enter="login"  @keyup.enter="login">登陆</div>
    </div>
    <div class="bottom-hint">
      <p class="p1">数字三一 智造未来</p>
      <p class="p2">@2019三一智能 版权所有 湘ICP备10005672号-3</p>
    </div>
  </div>
</template>

<script>
import { getLoginCode, userLogin } from '@/services'
import { setToken } from '@/utils/cookies'
import { encryptByDES } from '@/utils/des.js'
export default {
  name: "Login",

  data() {
    return {
      clientHeight: null,
      codeImg: '',
      sessionkeyid: '',
      form: {
        userAccount: '',
        password: '',
        code: ''
      },
      rules: {
        userAccount: [
          { required: true, message: "请输入账号", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
    };
  },

  created() {
    this.clientHeight = `${document.body.clientHeight}px`;
    this.getLoginCode()
    setTimeout(() => {
      // 设置焦点
      this.$refs.userAccount.$el.children[0].focus()
    })
  },

  methods: {
     /**
     * 获取验证码
     * @author tanguozheng
     * @date 2021-12-03 09:03
     * @param {*}
     * @returnsy
     */
    getLoginCode() {
      getLoginCode().then(res => {
        this.sessionkeyid = res.headers.sessionkeyid || ''
        this.codeImg = (window.URL || window.webkitURL).createObjectURL(res)
      })
    },

     /**
     * 登陆
     * @author tanguozheng
     * @date 2021-05-07 11:18
     * @param {*}
     * @returns
     */
    login() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let desPassword = encryptByDES(this.form.password)
          userLogin({ ...this.form, password: desPassword}, {sessionkeyid: this.sessionkeyid}).then(res => {
            if(res.code === 200) {
              let { token = '', userName = '', userId = ''} = res.content || {}
              setToken(token) // 保存用户token
              sessionStorage.setItem('userName', userName)
              sessionStorage.setItem('userId', userId)
              window.location.href = location.origin
              this.$message.success('登陆成功')
            } else if(res.code === 403) {
              this.form.code = ''
              this.getLoginCode()
              this.$message.error(res.message)
            } else{
              this.$message.error(res.message)
            }
          })
        } else {
          return false;
        }
      });
    },

  }
};
</script>
<style lang="scss">

</style>
<style lang="scss">
.el-input__inner{
  height: 48px;
  line-height: 48px;
  padding-left: 56px !important;
}
.el-input__prefix{
    align-items: center;
    display: flex;
    left: 18px;
}
.el-input-group__append{
  background-color: #ffffff;
  color: #000000;
  font-weight: 500;
}
.login-page {
  position: relative;
  width: 100%;
  height: 100vh;
  background: url("../../assets/images/login-bg.png") 0% 0% / cover;
  //   background-repeat: no-repeat;
  //   background-size: 100%, 100%;
  padding: 24px;
  box-sizing: border-box;
  .logo {
    img {
      height: 48px;
      width: 180px;
    }
  }
  .sys-name {
    position: absolute;
    top: 50%;
    left: 48px;
    transform: translateY(-50%);
    .name-zh {
      font-size: 40px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }
    .name-en {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.6);
      margin-top: 10px;
    }
  }
  .login-form-box{
    padding: 64px;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 616px;
    height: 512px;
    background: #FFFFFF;
    box-shadow: 0px 24px 12px 0px rgba(0, 0, 0, 0.02), 0px 6px 12px 0px rgba(0, 0, 0, 0.02), 0px 0px 6px 0px rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    h1{
      font-size: 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.9);
    }
    .line{
      display: inline-block;
      width: 60px;
      height: 4px;
      background: #FA5151;
      margin-top: 10px;
    }
    .form{
      margin-top: 64px;
      .icon_left{
       width: 20px;
    height: 20px;
    vertical-align: -webkit-baseline-middle;
      }
    }
    .logo-submit{
      width: 100%;
      padding: 13px 0;
      color: #ffffff;
      font-size: 16px;
      background: #FA5151;
      border-radius: 2px;
      cursor:pointer;
      text-align: center;
    }
  }

  .bottom-hint {
    box-sizing: border-box;
    display: flex;
    position: absolute;
    bottom: 24px;
    justify-content: space-between;
    width: calc(100% - 48px);
    font-weight: 400;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    .p1 {
      color: #ffffff;
    }
    .p2 {
      color: rgba(0, 0, 0, 0.3);
    }
  }
}
</style>
